export const environment = {
  production: true,
  sentryEnvironment: 'test',
  clientId: 'vtg-ui',
  clientRoot: 'https://test.vertigoinsurance.com/',
  stsAuthority: 'https://test.vertigoinsurance.com/idp/',
  baseAuthUrl: '/api/auth/',
  baseSubmissionUrl: '/api/submission/',
  baseVectorUrl: '/api/vector/',
  baseRateEngineUrl: '/api/ratingengine/',
  baseNotificationUrl: '/api/notification/',
  baseTaskUrl: '/api/task/',
  baseCompanyUrl: '/api/company/',
  baseResourceUrl: '/api/resource/',
  baseNoteUrl: '/api/note/',
  basePhotoBlobUrl: 'https://storagepreprodvertigo.blob.core.windows.net/pictures/',
  baseMapTilesBlobUrl: 'https://storagepreprodvertigo.blob.core.windows.net/map-tiles/tilesWData/'
};
